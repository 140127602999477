import React, {useState, useEffect} from 'react';
import {Provider} from "react-redux";
import storeReduex from "./store/redux";

import RouteConfig from "./router";

import {ConfigProvider} from "antd";

import './App.css';

import {MobileOrTabletLog} from "./utils/mixin";

import VConsole from 'vconsole';

function App() {

    let view_type = window.law_template; // 判断模板类型
    // view_type = 6;
    // view_type = 7;

    const addScriptMetaPixel = (pixel_id) => {
        const script = document.createElement('script');
        script.textContent = `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');fbq('init', ${pixel_id});`;
        document.head.appendChild(script);

        const noscript = document.createElement('noscript');
        noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=272860098976670&ev=PageView&noscript=1" />`;
        document.head.appendChild(noscript);
    }

    const switchRenderAntCssConfig = (type) => {
        let view_type6 = {
            token: {
                colorPrimary: '#6842ff',
                colorPrimaryHover: '#A48EFF',
                colorText: '#FFFFFF',
                colorTextPlaceholder: '#AAADBE',
                colorLink: '#FFFFFF',
                fontFamily: 'Nunito,Arial,"Helvetica Neue",Helvetica,sans-serif',
            },
            components: {
                Layout: {
                    bodyBg: '#0C0D14', //test
                    headerBg: '#212233',
                    siderBg: '#0C0D14',
                },
                Card: {
                    colorBgContainer: '#1D1E25',
                    colorBorderSecondary: '#1F2030',
                    colorText: '#373952',
                    colorTextHeading: '#373952',
                    colorTextDescription: '#373952',
                },
                Menu: {
                    darkItemBg: '#0C0D14',
                    darkItemHoverBg: '#0C0D14',
                    darkItemSelectedBg: '#0C0D14',
                    darkItemSelectedColor: '#8668FF',
                    activeBarWidth: 6,
                    activeBarBorderWidth: 6,
                    collapsedIconSize: 20,
                    iconSize: 20,
                },
                Modal: {
                    contentBg: '#212233',
                },
                Message: {
                    contentBg: '#212233',
                },
                // Input: {
                //     activeBg: '373952',
                //     activeBorderColor: '373952',
                //     hoverBg: '373952',
                //     hoverBorderColor: '373952',
                // },
            }
        };
        let view_type7 = {
            token: {
                colorPrimary: '#25d8e5',
                    colorPrimaryHover: '#2ba8c8',
                    colorPrimaryBg: '#12222c',
                    colorText: '#FFFFFF',
                    colorTextPlaceholder: '#AAADBE',
                    colorLink: '#FFFFFF',
                    fontFamily: 'Nunito,Arial,"Helvetica Neue",Helvetica,sans-serif',
            },
            components: {
                Layout: {
                    bodyBg: '#163042', //test
                    headerBg: '#07131b',
                    siderBg: '#12222c',
                },
                Card: {
                    colorBgContainer: '#1D1E25',
                    colorBorderSecondary: '#1F2030',
                    colorText: '#373952',
                    colorTextHeading: '#373952',
                    colorTextDescription: '#373952',
                },
                Menu: {
                    darkItemBg: '#12222c',
                    darkItemHoverBg: '#12222c',
                    darkItemSelectedBg: '#12222c',
                    darkItemSelectedColor: '#25d8e5',
                    activeBarWidth: 6,
                    activeBarBorderWidth: 6,
                    collapsedIconSize: 20,
                    iconSize: 20,
                },
                Modal: {
                    contentBg: '#12222c',
                },
                Message: {
                    contentBg: '#12222c',
                },
                // Input: {
                //     activeBg: '373952',
                //     activeBorderColor: '373952',
                //     hoverBg: '373952',
                //     hoverBorderColor: '373952',
                // },
            }
        }

        switch (type) {
            case 6:
                return view_type6;
            case 7:
                return view_type7;
            default:
                return view_type6;
        }
    }

    const handleUserInfo = () => {
        const random12DigitNumber = Math.floor(100000000000 + Math.random() * 900000000000);
        function generateRandomName() {
            var adjectives = ["Happy", "Sunny", "Brave", "Clever", "Gentle", "Kind"];

            var randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
            var random6DigitNumber =  Math.floor(100000 + Math.random() * 900000);

            return randomAdjective + random6DigitNumber;
        }

        var randomName = generateRandomName();
        let temp = {
            "provider": "facebook",
            "data": {
                "userID": random12DigitNumber,
                "id": random12DigitNumber,
                "name": randomName,
            }
        }
        window.localStorage.setItem("userInfo", JSON.stringify(temp));
    }

    console.log('process.env.NODE_ENV===', process.env.NODE_ENV);
    if(process.env.NODE_ENV === 'development'){
        const vConsole = new VConsole();
    }

    useEffect(() => {
        MobileOrTabletLog();
        if (window.localStorage.getItem('userInfo') == null) {
            handleUserInfo();
        }
        if(window.law_template){
            window.localStorage.setItem('law_template', window.law_template);
        }
        let lawOtherCfg = window.lawOtherCfg;
        // let lawOtherCfg = {
        //     fb_pixel_id: "272860098976670"
        // }
        if(lawOtherCfg && lawOtherCfg.fb_pixel_id){
            addScriptMetaPixel(lawOtherCfg.fb_pixel_id);
        }

    }, [])

    return (
        <ConfigProvider
            theme={switchRenderAntCssConfig(view_type)}
        >
            <Provider store={storeReduex}>
                <div className="App">
                    <RouteConfig></RouteConfig>
                </div>
            </Provider>
        </ConfigProvider>
    );
}

export default App;

import React, { Component } from 'react';
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import asyncComponent from '../utils/asyncComponent';

const Layout1 = asyncComponent(() => import("../component_1/Layout/Layout"));
const Home1 = asyncComponent(() => import("../component_1/pages/Home/Home"));
const Search1 = asyncComponent(() => import("../component_1/pages/Search/Search"));
const Details1 = asyncComponent(() => import("../component_1/pages/Details/Details"));
const Category1 = asyncComponent(() => import("../component_1/pages/Category/Category"));
const Test1 = asyncComponent(() => import("../component_1/pages/Test/Test"));

const Layout2 = asyncComponent(() => import("../component_2/Layout/Layout"));
const Home2 = asyncComponent(() => import("../component_2/pages/Home/Home"));
const Search2 = asyncComponent(() => import("../component_2/pages/Search/Search"));
const Details2 = asyncComponent(() => import("../component_2/pages/Details/Details"));
const Category2 = asyncComponent(() => import("../component_2/pages/Category/Category"));
const Test2 = asyncComponent(() => import("../component_2/pages/Test/Test"));


export default class RouteConfig extends Component{
    constructor(props) {
        super(props);
        this.state = {
            view_type: window.law_template
        };
        // this.state.view_type = 6;
        // this.state.view_type = 7;
    }

    render(){
        switch (this.state.view_type) {
            case 6:
                return(
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Layout1 />} >
                                <Route path="" element={<Home1 />} />
                                <Route path="home" element={<Home1 />} />
                                <Route path="page/search" element={<Search1 />} />
                                <Route path="page/details" element={<Details1 />} />
                                <Route path="page/category" element={<Category1 />} />
                                <Route path="page/testtest" element={<Test1 />} />
                                {/*<Route path="*" element={<Navigate to="home" />} ></Route>*/}
                            </Route>
                        </Routes>
                    </BrowserRouter>
                );
            case 7:
                return(
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Layout2 />} >
                                <Route path="" element={<Home2 />} />
                                <Route path="home" element={<Home2 />} />
                                <Route path="page/search" element={<Search2 />} />
                                <Route path="page/details" element={<Details2 />} />
                                <Route path="page/category" element={<Category2 />} />
                                <Route path="page/testtest" element={<Test2 />} />
                                {/*<Route path="*" element={<Navigate to="home" />} ></Route>*/}
                            </Route>
                        </Routes>
                    </BrowserRouter>
                );
            default:
                // 渲染默认模板
                return (
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Layout1 />} >
                                <Route path="" element={<Home1 />} />
                                <Route path="home" element={<Home1 />} />
                                <Route path="page/search" element={<Search1 />} />
                                <Route path="page/details" element={<Details1 />} />
                                <Route path="page/category" element={<Category1 />} />
                                <Route path="page/testtest" element={<Test1 />} />
                                {/*<Route path="*" element={<Navigate to="home" />} ></Route>*/}
                            </Route>
                        </Routes>
                    </BrowserRouter>
                );
        }
    }
}
